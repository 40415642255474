import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`The DynamicIcon serves any icon you try to call. We use `}<a parentName="p" {...{
        "href": "https://feathericons.com"
      }}>{`Feather Icons`}</a>{` made by `}<a parentName="p" {...{
        "href": "https://twitter.com/colebemis"
      }}>{`@colebemis`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      